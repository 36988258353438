html, body { margin:0; padding:0;}

svg { 
  z-index: -10;
  overflow: hidden;
}

.like { 
  right: 0%; 
}

.dislike { 
  left: 0%; 
}
